import React, { useCallback, useId } from 'react';
import { useNavigate } from 'react-router';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Divider } from '../../assets/Divider/Divider';
import { Icon } from '../../assets/Icon/Icon';
import { selectors, transitions } from '../../controls/shared/styles';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { isDefined } from '../../utilities/isDefined';
import { HStack } from '../../utilities/Stack/HStack';
import {
  AccessLevel,
  isAccessLevelVisible,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import { useViewport } from '../../utilities/useViewport';
import { Badge } from '../Badge/Badge';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '../DropdownMenu/DropdownMenu';
import { IconTooltip } from '../Tooltip/Tooltip';

const TabContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  boxShadow: '$$focusRing',
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
    $$focusRing: shadows.focusRingLight,

    [darkThemeSelector]: {
      $$focusRing: shadows.focusRingDark,
    },
  },

  variants: {
    hasAnnotation: {
      true: {
        '@notDesktop': {
          paddingRight: '$6',
        },

        '@desktop': {
          paddingRight: '$4',
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        '&::after': {
          content: '',
          position: 'absolute',
          left: 0,
          right: 0,
          display: 'block',
          backgroundColor: '$$indicatorColor',
          borderRadiusTop: '99em',
        },

        color: colors.headingBrandLight,
        $$iconColor: colors.bodyBrandLight,
        $$indicatorColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
          $$iconColor: colors.bodyBrandDark,
          $$indicatorColor: colors.iconBrandDark,
        },
      },
      false: {
        color: colors.headingNeutralLight,
        $$iconColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
          $$iconColor: colors.bodyNeutralDark,
        },

        [selectors.hover]: {
          color: colors.headingNeutralLight,
          $$iconColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            color: colors.headingNeutralDark,
            $$iconColor: colors.bodyNeutralDark,
          },
        },
      },
    },
    hasSize: {
      'x-small': {
        '&::after': {
          height: '$2',
        },

        '@notDesktop': {
          padding: '$2 $6',
          borderRadius: '$8',

          '&::after': {
            bottom: '-$6',
          },
        },

        '@desktop': {
          padding: '0 $4',
          borderRadius: '$6',

          '&::after': {
            bottom: '-$8',
          },
        },
      },
      small: {
        '&::after': {
          height: '$4',
        },

        '@notDesktop': {
          padding: '$4 $8',
          borderRadius: '$10',

          '&::after': {
            bottom: '-$10',
          },
        },

        '@desktop': {
          padding: '$4 $6',
          borderRadius: '$10',

          '&::after': {
            bottom: '-$8',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        color: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,
        $$indicatorColor: colors.internalIconLight,

        [selectors.hover]: {
          color: colors.internalHeadingLight,
          $$iconColor: colors.internalBodyLight,
        },

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
          $$indicatorColor: colors.internalIconDark,

          [selectors.hover]: {
            color: colors.internalHeadingDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
  ],
});

const TabIcon = styled(Icon, {
  color: '$$iconColor',

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },

      small: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

type TabPropSize = 'x-small' | 'small';
export interface TabProps {
  /**
   * The level required to access the tab.
   */
  accessLevel?: AccessLevel;
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Written label for the tab.
   * */
  children?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Key for the tab.
   */
  key?: string;
  /**
   * Written label for the tab.
   * */
  label?: React.ReactNode;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Marks the currently selected tab.
   * */
  selected?: boolean;
  /**
   * Size of the tab.
   */
  size?: TabPropSize;
  /**
   * A tooltip to be displayed on hover.
   */
  tooltip?: React.ReactNode;
  /**
   * Routing
   */
  as?: React.ElementType;
  to?: string;
}

function TabInner<Tag extends React.ElementType>(
  {
    accessLevel: accessLevelProp,
    annotation,
    children,
    icon,
    internal,
    label,
    selected = false,
    size = 'small',
    tooltip,
    ...remaining
  }: PolymorphicComponentProps<Tag, TabProps>,
  forwardedRef: PolymorphicRef<Tag>,
) {
  const accessLevel = useAccessLevelContext(accessLevelProp);
  return (
    <TabContainer
      type="button"
      role="tab"
      aria-selected={selected ? 'true' : 'false'}
      ref={forwardedRef}
      isSelected={selected}
      hasAnnotation={isDefined(annotation)}
      internal={internal || accessLevel === AccessLevel.Internal}
      hasSize={size}
      {...remaining}
    >
      <AlignStack
        direction="row"
        gap={size === 'x-small' ? 4 : 6}
        preset={size === 'x-small' ? 'small' : 'body'}
        start={isDefined(icon) && <TabIcon hasSize={size} icon={icon} />}
        end={
          (isDefined(annotation) || accessLevel || tooltip) && (
            <HStack align="center" spacing={4}>
              {tooltip && <IconTooltip icon="question" size={14} contents={tooltip} />}
              {isDefined(annotation) && (
                <Badge internal={internal} size={size} variant={selected ? 'brand' : 'neutral'}>
                  {annotation}
                </Badge>
              )}
              {accessLevel && isAccessLevelVisible(accessLevel) && (
                <FeatureBadge type={accessLevel} size="x-small" />
              )}
            </HStack>
          )
        }
      >
        <Text>{label || children}</Text>
      </AlignStack>
    </TabContainer>
  );
}

export const Tab = React.forwardRef(TabInner) as <D extends React.ElementType = 'button'>(
  props: PolymorphicComponentProps<D, TabProps> & { ref?: PolymorphicRef<D> },
) => ReturnType<typeof TabInner>;

const TabDivider = styled(Divider, {
  padding: '$4',
});

const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
  height: '100%',

  '@notDesktop': {
    width: '100%',
  },
});

export type TabsProps = {
  /**
   * Pass `Tab` components into this for automatically laid out tabs.
   * */
  children?: React.ReactNode;
  /**
   * Size of the tabs.
   */
  size?: TabPropSize;
  /**
   * Tabs passed in as an array.
   */
  tabs?: TabProps[];
  /**
   * Subtabs passed in as an array.
   */
  subtabs?: TabProps[];
};

function TabsArray({ size, tabs, subtabs }: TabsProps) {
  const { breakpoint } = useViewport();
  const navigate = useNavigate();
  const key = useId();
  const selectedTab =
    tabs?.find((tab) => tab.selected) || subtabs?.find((subtab) => subtab.selected);

  const onClickTab = useCallback(
    (tab: TabProps, e: Event) => {
      if (tab.to) {
        navigate(tab.to);
      }

      tab.onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>);
    },
    [navigate],
  );

  return breakpoint === 'desktop' ? (
    <>
      {tabs?.map((tab) => <Tab size={size} key={`${key}-tab-${tab.label}`} {...tab} />)}
      {tabs && subtabs && <TabDivider appearance="thick" direction="column" variant="neutral" />}
      {subtabs?.map((subtab) => (
        <Tab size={size} key={`${key}-subtab-${subtab.label}`} {...subtab} />
      ))}
    </>
  ) : (
    <DropdownMenu>
      <DropdownMenuButton
        arrangement="leading-icon"
        icon={selectedTab?.icon}
        labelAlign="start"
        labelStretch
        size="medium"
        width="100%"
        annotation={selectedTab?.annotation}
      >
        {selectedTab?.label}
      </DropdownMenuButton>
      <DropdownMenuPopover align="start" matchTrigger>
        {tabs && (
          <DropdownMenuGroup>
            {tabs?.map((tab) => (
              <DropdownMenuItem
                key={`${key}-tab-${tab.label}`}
                onSelect={(e) => onClickTab(tab, e)}
                accessLevel={tab.accessLevel}
                annotation={tab.annotation}
                icon={tab.icon}
                label={tab.label || tab.children}
                internal={tab.internal}
                tooltip={tab.tooltip}
                selected={selectedTab?.label === tab.label}
              />
            ))}
          </DropdownMenuGroup>
        )}
        {subtabs && (
          <DropdownMenuGroup>
            {subtabs?.map((subtab) => (
              <DropdownMenuItem
                key={`${key}-subtab-${subtab.label}`}
                onSelect={(e) => onClickTab(subtab, e)}
                accessLevel={subtab.accessLevel}
                annotation={subtab.annotation}
                icon={subtab.icon}
                label={subtab.label || subtab.children}
                internal={subtab.internal}
                tooltip={subtab.tooltip}
                selected={selectedTab?.label === subtab.label}
              />
            ))}
          </DropdownMenuGroup>
        )}
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

export function Tabs({ children, size, tabs, subtabs, ...remaining }: TabsProps) {
  const isArray = Array.isArray(tabs) || Array.isArray(subtabs);
  return (
    <TabsContainer role="tablist" {...remaining}>
      {isArray ? (
        <TabsArray size={size} tabs={tabs} subtabs={subtabs} />
      ) : (
        children || tabs || subtabs
      )}
    </TabsContainer>
  );
}
